import { LatLngExpression } from 'leaflet';

interface IDefaultPOIData {
	challenge: string;
	special: string;
	chests: string;
	onslaught: string;
	nullLoc: LatLngExpression;
}

export const DefaultPOIData: IDefaultPOIData = {
	challenge: 'Obtained through the Challenge Machine',
	special: 'Dropped from Special/Elite kills',
	chests: 'Dropped from Special/Elite kills or golden chests',
	onslaught: 'Dropped during the onslaught gamemode.',
	nullLoc: [0, 0],
};

export enum MapIds {
	allOutbreakMaps = 'outbreak',
	zoo = 'zoo',
	ruka = 'ruka',
	duga = 'duga',
	alpine = 'alpine',
	golova = 'golova',
	sanatorium = 'sanatorium',
	collateral = 'collateral',
	armada = 'armada',
	dieMaschine = 'dieMaschine',
	dieMaschineUnderground = 'dieMaschine_underground',
	firebaseZ = 'firebaseZ',
	firebaseZSpawn = 'firebaseZ_spawn',
	mauerDerToten = 'mauerDerToten',
	mauerDerTotenStreets = 'mauerDerToten_streets',
	forsaken = 'forsaken',
	forsakenUnderground = 'forsaken_underground',
	miami = 'miami',
	satellite = 'satellite',
	moscow = 'moscow',
	nuketown = 'nuketown',
	thePines = 'thePines',
	express = 'express',
	rush = 'rush',
	echelon = 'echelon',
	driveIn = 'driveIn',
	cartel = 'cartel',
	crossroads = 'crossroads',
	raid = 'raid',
	apocalypse = 'apocalypse',
	yamantau = 'yamantau',
	standoff = 'standoff',
	collateralOn = 'collateralOn',
	checkmate = 'checkmate',
	garrison = 'garrison',
	deprogram = 'deprogram',
	//b06
	libertyFalls = 'liberty_falls',
}

export enum Faction {
	Requiem = 'Requiem',
	Omega = 'Omega',
	Maxis = 'Maxis',
	Dark_Aether = 'Dark Aether',
}

export enum Season {
	Preseason = 'Preseason',
	Onslaught = 'Onslaught',
	Season1 = 'Season 1',
	Season2 = 'Season 2',
	Season3 = 'Season 3',
	Season4 = 'Season 4',
	Season5 = 'Season 5',
	Season6 = 'Season 6',
}

export enum IntelType {
	Audio = 'Audio Logs',
	Docs = 'Documents',
	Radio = 'Radio Transmissions',
	Artifact = 'Artifacts',
}

export enum MiscIconTypes {
	general = 'general',

	ammoCrate = 'ammo_crate',
	arsenal = 'arsenal',
	craftingTable = 'crafting_table',
	demented = 'demented',
	fishing = 'fishing',
	monkey = 'monkey',
	mysteryBox = 'mystery_box',
	papMachine = 'pap_machine',
	radio = 'radio',
	redRift = 'tunnel_red',
	rift = 'tunnel_pink',
	trialComputer = 'trial_computer',
	wallbuy = 'wallbuy',
	wunderFizz = 'wunder_fizz',
	zipline = 'zipline',
	ziplineUp = 'ziplineUp',
	ziplineDown = 'ziplineDown',
	tunnel = 'tunnel',
	tunnelRed = 'tunnel_red',
	tunnelPink = 'tunnel_pink',
	portal = 'portal',

	rampageInducer = 'rampage_inducer',

	staminUp = 'stamin_up',
	quickRevive = 'quick_revive',
	juggernog = 'juggernog',
	speedCola = 'speed_cola',
	muleKick = 'mule_kick',
	elementalPop = 'elemental_pop',
	deathPerception = 'death_perception',
	tombstoneSoda = 'tombstone_soda',
	deadshotDaiquiri = 'deadshot_daiquiri',
	phdSlider = 'phd_slider',
}

export interface IIntelItem {
	readonly id: string;
	readonly faction: Faction;
	readonly season: Season;
	readonly typeDesc: IntelType;
	readonly loc: LatLngExpression;
	readonly map: string | undefined;
	readonly title: string;
	readonly desc: string;
	readonly img?: string;
}

export class IntelItem implements IIntelItem {
	id!: string;
	faction!: Faction;
	season!: Season;
	typeDesc!: IntelType;
	loc!: LatLngExpression;
	map!: string | undefined;
	title!: string;
	desc!: string;
	img?: string | undefined;

	constructor(id, faction, season, typeDesc, loc, map, title, desc, img = '') {
		this.id = id;
		this.faction = faction;
		this.season = season;
		this.typeDesc = typeDesc;
		this.loc = loc;
		this.map = map;
		this.title = title;
		this.desc = desc;
		this.img = img;
	}
}

const staticIntelStore: IIntelItem[] = [
	/////////////////////Requiem/////////////////////////
	{
		id: 'RS0Au1',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [117.48346338003188, 388],
		map: MapIds.dieMaschineUnderground,
		title: 'The Incident',
		desc: 'Right next to power in the left corner on a shelf.',
		img: 'EGqRNBA',
	},
	{
		id: 'RS0Au2',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [150.77158419588412, 376.92896048971033],
		map: MapIds.dieMaschineUnderground,
		title: 'Parallel World',
		desc: 'Near PaP, behind the stairs leading up while in the Dark Aether.',
		img: '2LplDYe',
	},
	{
		id: 'RS0Do1',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Endstation Approval Letter',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Do2',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Spacetime Breach Sketch',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Do3',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'D.I.E. Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Do4',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Der Wechsler Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Do5',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'V Cipher #1',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Do6',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'V Cipher #2',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS0Ra1',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Officer Weaver',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra2',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Doctor Grey',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra3',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Dimension of Death',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra4',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Doctor Strauss',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra5',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Aetherium',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra6',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Major Carver',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ra7',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'New Arms Race',
		desc: 'You can only get one radio transmission per game.',
	},
	{
		id: 'RS0Ar1',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [170.0631722113111, 201.81997835508457],
		map: MapIds.dieMaschineUnderground,
		title: 'D.I.E. Remote Control',
		desc: 'Gotten as part of the free D.I.E Machine Quest',
		img: '7VkXI09',
	},
	{
		id: 'RS0Ar2',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [167.01550190613514, 257.8345330200728],
		map: MapIds.dieMaschine,
		title: 'D.I.E. Machine',
		desc: 'Get the free D.I.E Machine from the quest',
		img: 'CEAruXt',
	},
	{
		id: 'RS0Ar3',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [182.80600838865897, 350.61889795537377],
		map: MapIds.dieMaschineUnderground,
		title: 'Aetherscope',
		desc: 'Part of the main quest. You have to craft it.',
		img: 'rgoEclM',
	},
	{
		id: 'RS0Ar4',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [340.1425322096326, 329.34435658392994],
		map: MapIds.dieMaschineUnderground,
		title: "Doctor Vogel's Diary",
		desc: 'When you have crafted the Aetherscope, it can be found in the Dark Aether next to the computer in the med bay.',
		img: 'yGC78xG',
	},
	{
		id: 'RS0Ar5',
		faction: Faction.Requiem,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [319.01055069265954, 351.936179716442],
		map: MapIds.dieMaschine,
		title: 'Decontamination Agent',
		desc: 'Dropped from a tree after the broken tank step of the main quest. Needs to be taken back to the cabinet in the med bay.',
		img: 'ci5KPI7',
	},
	{
		id: 'RS1Au1',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [344.7238827806349, 363.5],
		map: MapIds.dieMaschineUnderground,
		title: 'Exo-Element 1',
		desc: 'Obtained from table in med bay, by easter egg computer. On the far wall, close to the box location.',
		img: '3kMPPiH',
	},
	{
		id: 'RS1Au2',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [195.48976325589638, 200],
		map: MapIds.dieMaschineUnderground,
		title: 'New Orders',
		desc: 'Obtained from a table in weapons lab, close to the dog gas collection point for the nova 5 WW upgrade.',
		img: 'NMx9YSb',
	},
	{
		id: 'RS1Au3',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [229.97149317254832, 351.5],
		map: MapIds.firebaseZ,
		title: 'Ravenov',
		desc: 'On a table in the outer ring of Data Center, first floor near a cabinet of data reels.',
		img: 'XKHCELz',
	},
	{
		id: 'RS1Au4',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [388.95457527930483, 247],
		map: MapIds.firebaseZSpawn,
		title: 'They Took Her',
		desc: 'On a bench above PaP.',
		img: 'fU5ZGiA',
	},
	{
		id: 'RS1Au5',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [208.9737276112786, 208],
		map: MapIds.firebaseZ,
		title: 'Meeting Of Minds',
		desc: 'In military Command, under the desk near the zombie barrier.',
		img: 'QIZ1vFt',
	},
	{
		id: 'RS1Do1',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Doctor Vogel Entry 203',
		desc: 'Obtained from megaton drop.',
	},
	{
		id: 'RS1Do2',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Outbreak Zones Timeline',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS1Do3',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Requiem Formation Memo',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS1Do4',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Requiem Staffing Announcement',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS1Do5',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'C Cipher',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS1Ra1',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: "Omega's Advantage",
		desc: '',
	},
	{
		id: 'RS1Ra2',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'This Is Personal',
		desc: '',
	},
	{
		id: 'RS1Ra3',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Peck',
		desc: '',
	},
	{
		id: 'RS1Ra4',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Between Us',
		desc: '',
	},
	{
		id: 'RS1Ra5',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'W.A.N.D.',
		desc: '',
	},
	{
		id: 'RS1Ra6',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'The Stick',
		desc: '',
	},
	{
		id: 'RS1Ra7',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Blacksmiths and Architects',
		desc: '',
	},
	{
		id: 'RS1Ra8',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Not Unlike Oxygen',
		desc: '',
	},
	{
		id: 'RS1Ra9',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'A Storied Career',
		desc: '',
	},
	{
		id: 'RS1Ra10',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'The Thick Of It',
		desc: '',
	},
	{
		id: 'RS1Ra11',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Transformative Properties',
		desc: '',
	},
	{
		id: 'RS1Ra12',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Genetic Secrets',
		desc: '',
	},
	{
		id: 'RS1Ar1',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [158.24646657208723, 287.25],
		map: MapIds.firebaseZ,
		title: 'RAI K-84 Blueprint',
		desc: 'On the wall in Weapons Lab.',
		img: 'AZfBKTe',
	},
	{
		id: 'RS1Ar2',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [346.4590973576875, 448],
		map: MapIds.firebaseZ,
		title: 'Weapon Trial Folder',
		desc: "On the ground next to Kuhlklay's Body.",
		img: '3y6mDXU',
	},
	{
		id: 'RS1Ar3',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [292.4648430572796, 330.5],
		map: MapIds.firebaseZ,
		title: "Kuhlklay's Diary",
		desc: 'Obtained from a locker, requires the locker key from the quest.',
	},
	{
		id: 'RS1Ar4',
		faction: Faction.Requiem,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [159.3725006650115, 282.625],
		map: MapIds.firebaseZ,
		title: 'RAI K-84 Prototype',
		desc: 'Obtain the ww. Needs to be the free one from the quest.',
		img: 'HqQqEQ4',
	},
	{
		id: 'RS2Au1',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [172.47761127859548, 358.5],
		map: MapIds.firebaseZ,
		title: 'Focal Point',
		desc: 'On the corner of the building on a ledge, near the satellite dish. Rocky Defence. Need to shoot it down.',
		img: 'QzNt7tS',
	},
	{
		id: 'RS2Au2',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [285.73321954247206, 271.75],
		map: MapIds.ruka,
		title: 'Like Flies To Honey',
		desc: 'In the Base Headquarters location on a long table.',
		img: 'itiaqDX',
	},
	{
		id: 'RS2Au3',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [292.5, 56],
		map: MapIds.alpine,
		title: 'A Grisly Affair',
		desc: 'In Base Cabins on the back of a truck behind the cabins.',
		img: 'Rd7VJUK',
	},
	{
		id: 'RS2Au4',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [157.93177892918823, 261.90189231808966],
		map: MapIds.golova,
		title: 'I See A Hierarchy',
		desc: 'On the top of the water tower near the gas station at the edge of the village proper.',
		img: 'k7hhYfY',
	},
	{
		id: 'RS2Au5',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [336, 265],
		map: MapIds.sanatorium,
		title: 'Quantum Entanglement',
		desc: 'On the pier in the back room of the building at the end.',
		img: 'GHNbEKJ',
	},
	{
		id: 'RS2Au6',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [195.47532363894305, 92.5],
		map: MapIds.ruka,
		title: 'Recon Rover Blues',
		desc: 'In the big building of the Old Farm location. When u enter the building, walk straight ahead, the audio log will be on top of a pink cabinet.',
		img: 'reb4ftu',
	},
	{
		id: 'RS2Au7',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [279, 285.5],
		map: MapIds.alpine,
		title: 'Killer Crystals',
		desc: 'In Ski Patrol in the big building upstairs in the smaller side room on a table.',
		img: 'KQcdy8S',
	},
	{
		id: 'RS2Do1',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'RE: Funding Allocation 1',
		desc: '',
	},
	{
		id: 'RS2Do2',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'RE: Funding Allocation 2',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS2Do3',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Berlin COMINT',
		desc: DefaultPOIData.special,
	},
	{
		id: 'Rs2Do4',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Operation Threshold Authorization',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'Rs2Do5',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Domino Effect',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'Rs2Do6',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Recon Rover Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'Rs2Do7',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Disruptor Satellite Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS2Ra1',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Nose To Nose',
		desc: '',
	},
	{
		id: 'RS2Ra2',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Race Against Time',
		desc: '',
	},
	{
		id: 'RS2Ra3',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'How We Win',
		desc: '',
	},
	{
		id: 'RS2Ra4',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Under Observation',
		desc: '',
	},
	{
		id: 'RS2Ar1',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Zombie Head',
		desc: 'Collected through the Defence objective.',
		img: 'c7IF0Yi',
	},
	{
		id: 'RS2Ar2',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [184.99267893868938, 142.25],
		map: MapIds.golova,
		title: 'Aetherium Canister',
		desc: 'Between the pipes connecting the Silo with the building.',
		img: 'MIWOwX9',
	},
	{
		id: 'RS2Ar3',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [91.24241490800614, 323.625],
		map: MapIds.ruka,
		title: 'Aetherium Crystal Shard',
		desc: "In the burnt forest section, right up against the edge of the map about halfway down. It's on top of a big rock.",
		img: 'z8ot1vM',
	},
	{
		id: 'RS2Ar4',
		faction: Faction.Requiem,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [256, 167.5],
		map: MapIds.alpine,
		title: 'Frost Blast Canister',
		desc: 'Look on top of the circular ski lift station on the western side of the map near the Lodge and the Pro Shop.',
		img: 'l1ryasI',
	},
	{
		id: 'RS3Au1',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [369.5, 284],
		map: MapIds.duga,
		title: 'The Beacon',
		desc: 'In the Chemical Processing area, on the desk in the office of the big building.',
		img: 'FoHnqCT',
	},
	{
		id: 'RS3Au2',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [344, 427.5],
		map: MapIds.duga,
		title: 'Doubts',
		desc: 'In the SAM site, in the big hanger on a box marked with a red star.',
		img: 'xscpwPW',
	},
	{
		id: 'RS3Au3',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [216.47308920021277, 87.5],
		map: MapIds.ruka,
		title: 'Death Squads',
		desc: 'In the old farm area. In the green tent, under a table.',
		img: '0AFDpdP',
	},
	{
		id: 'RS3Au4',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [174, 304],
		map: MapIds.duga,
		title: 'Eyes Only',
		desc: 'In the Worker Services building. On the second floor, on a desk in one of the offices.',
		img: 'tDUcVSG',
	},
	{
		id: 'RS3Au5',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [204.5, 274],
		map: MapIds.duga,
		title: 'Operation Baldr',
		desc: 'In Radar Control. Sitting on the table next to the couch in the Office on the second floor.',
		img: 'SZNbR2I',
	},
	{
		id: 'RS3Au6',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [286.5, 298.5],
		map: MapIds.sanatorium,
		title: 'Dissent And Division',
		desc: "On the monorail track in South Lake. It's in the support pillar in the middle of the lake.",
		img: 'ggnGpuZ',
	},
	{
		id: 'RS3Au7',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [303, 345.5],
		map: MapIds.sanatorium,
		title: 'News From Upstairs',
		desc: 'Under the bridge. Right at the end of the bridge just before you go out of the map, on a little platform just above the surface of the water.',
		img: 'lffwsAV',
	},
	{
		id: 'RS3Au8',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [219, 149.5],
		map: MapIds.alpine,
		title: 'Embarrassment of Riches',
		desc: 'In the lodge. On the third floor sitting on the bar.',
		img: '4JEjpiH',
	},
	{
		id: 'RS3Do1',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Extraction Rocket Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do2',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Dark Aether Object Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do3',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Crate Memo',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do4',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'E.C.M. Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do5',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Investigation Request',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do6',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Zykov Assessment',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do7',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Power Nodes Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Do8',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'J Cipher',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS3Ra1',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Progress Update',
		desc: '',
	},
	{
		id: 'RS3Ra2',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'A Visceral Process',
		desc: '',
	},
	{
		id: 'RS3Ra3',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Fish Out Of Water',
		desc: '',
	},
	{
		id: 'RS3Ra4',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Commander-In-Chief',
		desc: '',
	},
	{
		id: 'RS3Ar1',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [278.5, 127.5],
		map: MapIds.sanatorium,
		title: 'Energy Mine Canister',
		desc: 'On the roof of the 001 and 002 apartments in the Mechanic Shop area.',
		img: 'kD0F78e',
	},
	{
		id: 'RS3Ar2',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [367, 233],
		map: MapIds.duga,
		title: 'Essence Explosive',
		desc: 'In the bunker area, in the top most guard station.',
		img: 'jEIigTf',
	},
	{
		id: 'RS3Ar3',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [245.5, 332.5],
		map: MapIds.sanatorium,
		title: 'Requiem Patch',
		desc: 'In the Lakeside Dell area. Its on a little dock to the left of the big bridge.',
		img: 'lunLo0R',
	},
	{
		id: 'RS3Ar4',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [387.7990788716177, 353.95577832845237],
		map: MapIds.golova,
		title: 'Transformed Apple Crate',
		desc: 'In the barn of the lower farmstead. Should be pretty easy to spot.',
		img: 'R6GJJPc',
	},
	{
		id: 'RS3Ar5',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [191.5, 398.5],
		map: MapIds.duga,
		title: 'Beacon Device Component',
		desc: 'In the Electrical Substation. On one of the two taller structures, is very easy to see.',
		img: 'VRBaXVX',
	},
	{
		id: 'RS3Ar6',
		faction: Faction.Requiem,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [356.718080080043, 430.125],
		map: MapIds.alpine,
		title: 'Aether Shroud Canister',
		desc: "In the Maintenance section. It's inside one of the garages.",
		img: 'vZ3UGhv',
	},
	{
		id: 'RS4Au1',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [201.97447242418866, 286],
		map: MapIds.zoo,
		title: 'You Knew Better',
		desc: 'Next to the monorail up in the cages overpass.',
		img: 'AO8Q7jy',
	},
	{
		id: 'RS4Au2',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [332.61880985103744, 263.9375],
		map: MapIds.zoo,
		title: 'Aftermath',
		desc: 'On the desks in the center of the room.',
		img: 'AcAn4Fw',
	},
	{
		id: 'RS4Au3',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [256.1005169442849, 277.25],
		map: MapIds.golova,
		title: 'What Tomorrow Brings',
		desc: 'On a bar counter.',
		img: '9kPQa9A',
	},
	{
		id: 'RS4Au4',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [360.21051120045945, 283.75],
		map: MapIds.ruka,
		title: 'The Berlin Situation',
		desc: 'The audio log is on top of a railway tanker.',
		img: 'sDDbhJT',
	},
	{
		id: 'RS4Au5',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [221.11429054492226, 132.75],
		map: MapIds.zoo,
		title: 'Goes Without Saying',
		desc: 'The audio log is inside one of the buildings at the Industrial Complex, on top of the the middle table.',
		img: 'x3iVmM5',
	},
	{
		id: 'RS4Au6',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [303.05556562291264, 280.65884945678516],
		map: MapIds.mauerDerToten,
		title: 'Lost in Transit',
		desc: 'In the Ghost Station. Laying next to a pillar on the side the Mule Kick machine is.',
	},
	{
		id: 'RS4Au7',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [103.78745589513629, 472.14785263771023],
		map: MapIds.mauerDerTotenStreets,
		title: 'A Handful',
		desc: 'Inside the hidden area of Hotel Room 305.',
	},
	{
		id: 'RS4Au8',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [329.9196021776218, 185.50607794719141],
		map: MapIds.mauerDerToten,
		title: "Who's Laughing Now?",
		desc: 'In the Safe House. On a desk to the left of a door.',
		img: '9GEjfTF',
	},
	{
		id: 'RS4Au9',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [351.0639951945735, 164.25180305542324],
		map: MapIds.mauerDerToten,
		title: 'Bad Days',
		desc: 'Inside the Safehouse inside a locker. Only appears after Klaus has been built.',
		img: 'XiKCiQ3',
	},
	{
		id: 'RS4Au10',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [182.3751168341555, 152.49714880759007],
		map: MapIds.mauerDerToten,
		title: 'Final Order',
		desc: 'Inside the secret lab accessed during the main ee. On a table behind the pedestal used during the ee.',
		img: 'm0tNm0t',
	},
	{
		id: 'RS4Do1',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'DASA Station Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS4Do2',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'The Omega Eight',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS4Do3',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.sanatorium,
		title: "The Child's Note",
		desc: 'Obtained during the Outbreak EE#2',
	},
	{
		id: 'RS4Do4',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Operation Excision Report',
		desc: '',
	},
	{
		id: 'RS4Do5',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: "Where's My Robot?",
		desc: '',
	},
	{
		id: 'RS4Do6',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'CRBR-S Report',
		desc: '',
	},
	{
		id: 'RS4Do7',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'E Cipher',
		desc: '',
	},
	{
		id: 'RS4Do8',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'CRBR-S Blueprint',
		desc: '',
	},
	{
		id: 'RS4Do9',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Klaus Blueprint',
		desc: '',
	},
	{
		id: 'RS4Do10',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Berlin Outbreak Report',
		desc: '',
	},
	{
		id: 'RS4Do11',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: "Rico's Note",
		desc: 'Part of the main EE, can be found on the train.',
	},
	{
		id: 'RS4Do12',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Subject: Strange Communique',
		desc: 'Part of the main EE, can be found on the computer beside Klaus.',
	},
	{
		id: 'RS4Do13',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Subject: Urgent',
		desc: 'Part of the main EE, can be found on the computer beside Klaus.',
	},
	{
		id: 'RS4Do14',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'J Cipher 2',
		desc: 'Part of the main EE, can be found on the computer beside Klaus.',
	},
	{
		id: 'RS4Do15',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'EE-1 Harvester Blueprint',
		desc: '',
	},
	{
		id: 'RS4Do16',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'EE-1 Conversion Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS4Do17',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: "Vogel's Letter",
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS4Ra1',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Welcome Back',
		desc: '',
	},
	{
		id: 'RS4Ra2',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Patterns',
		desc: '',
	},
	{
		id: 'RS4Ra3',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Eye For An Eye',
		desc: '',
	},
	{
		id: 'RS4Ra4',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Marked Man',
		desc: '',
	},
	{
		id: 'RS4Ar1',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [246.4854805816634, 109.75],
		map: MapIds.zoo,
		title: 'Healing Aura Canister',
		desc: 'Underneath the sink in the corner.',
		img: 'NTI5zGr',
	},
	{
		id: 'RS4Ar2',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [416.95170242950877, 369.5],
		map: MapIds.zoo,
		title: 'Recon Rover Model',
		desc: 'Behind the sink in the bathroom, second floor.',
		img: 'iL1t8Le',
	},
	{
		id: 'RS4Ar3',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [304.976089010765, 73.875],
		map: MapIds.alpine,
		title: 'Ring of Fire Canister',
		desc: 'In a yellow hanging coat inside the southernmost base cabin.',
		img: 'Ukkarkd',
	},
	{
		id: 'RS4Ar4',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [244.11627888125105, 320.3125],
		map: MapIds.zoo,
		title: 'ECM Model',
		desc: 'The artifact is found on the shelf inside the Zoo Entrance office.',
		img: 'uUyufz6',
	},
	{
		id: 'RS4Ar5',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [103.6017379770531, 462.17348530573224],
		map: MapIds.mauerDerTotenStreets,
		title: "Klaus's Hands",
		desc: 'The hands are found around the neck of a scorched zombie in room 305.',
		img: 'y0rk1MH',
	},
	{
		id: 'RS4Ar6',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [115.54337132643306, 451.52283934547444],
		map: MapIds.mauerDerTotenStreets,
		title: 'CRBR-S',
		desc: 'In the Safe in rroom 305.',
		img: '05AhecN',
	},
	{
		id: 'RS4Ar7',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [296.73609119124524, 317.747499559232],
		map: MapIds.mauerDerToten,
		title: 'Warhead',
		desc: 'Part of the EE, can be found inside the train on an open chest',
		img: 'waVs3Fr',
	},
	{
		id: 'RS4Ar8',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [290.359361317248, 317.5268297579435],
		map: MapIds.mauerDerToten,
		title: "Rico's Keycard",
		desc: 'Part of the EE, can be found inside the train on a burnt corpse',
		img: '8XlPsfD',
	},
	{
		id: 'RS4Ar9',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [336.8310351797605, 202.84884675145662],
		map: MapIds.mauerDerToten,
		title: 'Hacking Helm',
		desc: 'Part of the EE, can be obtained by crafting onto Klaus. U must collect first the Antenna, Transistor and Electronic Boards',
		img: 'iuuHVb2',
	},
	{
		id: 'RS4Ar10',
		faction: Faction.Requiem,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [286.0552817646633, 37.73111969127485],
		map: MapIds.mauerDerTotenStreets,
		title: 'Improvised Collider',
		desc: 'Part of the EE, can be obtained by picking up corrupted uranium and using it on the workbench',
		img: 'BHetga7',
	},
	{
		id: 'RS5Au1',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [152.10509347612606, 373.5],
		map: MapIds.collateral,
		title: 'Peacemakers',
		desc: 'In the South dunes area. Near the Jump Pad, on the treads of the big machine, just to the west of the Jump pad.',
		img: 'uJxF3Lu',
	},
	{
		id: 'RS5Au2',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [365.68631426163495, 117.06689738832597],
		map: MapIds.collateral,
		title: 'Soul Searching',
		desc: "It's in the broken down building, in the back corner, close to the border of the region.",
		img: 'HnU7IVF',
	},
	{
		id: 'RS5Au3',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [344.28804705304697, 286],
		map: MapIds.armada,
		title: 'Unto The Breach',
		desc: "In the Gun Boat area. it's just behind a crate, near the Wunderfizz.",
		img: 'qgMsd9g',
	},
	{
		id: 'RS5Au4',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [264.9770975746793, 279.27491131317663],
		map: MapIds.armada,
		title: 'Butterscotch',
		desc: 'In Launch Bay area #3. Top Right most one, if looking at the big map.',
		img: 'sZeU3Xo',
	},
	{
		id: 'RS5Au5',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [252.80602314205777, 249.9248150443894],
		map: MapIds.armada,
		title: 'Desperate Measures',
		desc: 'On a table in the center of the Control Room area of the main boat.',
		img: '8PsBCYx',
	},
	{
		id: 'RS5Do1',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Dark Aether Vortex Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS5Do2',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Krafft Journal Page',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS5Do3',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Outbreak Expansions Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS5Do4',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Krafft Journal Page 2',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS5Do5',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Operation First Domino',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'RS5Ra1',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Updates',
		desc: '',
	},
	{
		id: 'RS5Ra2',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Busy Bees',
		desc: '',
	},
	{
		id: 'RS5Ra3',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Walking Notso',
		desc: '',
	},
	{
		id: 'RS5Ra4',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Act Three Time',
		desc: '',
	},
	{
		id: 'RS5Ar1',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [339.4830418187545, 403.61980124770054],
		map: MapIds.collateral,
		title: 'Frenzied Guard Canister',
		desc: 'On the roof of the Drapery.',
		img: 'xzgByHn',
	},
	{
		id: 'RS5Ar2',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [183.96168312612326, 110],
		map: MapIds.collateral,
		title: 'Aetherium Rocket Model',
		desc: "In the Oil Tanks area. It's under a staircase, sitting on a yellow pipe embedded in the wall.",
		img: 'mORszHJ',
	},
	{
		id: 'RS5Ar3',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [180.98899061533047, 278.0625],
		map: MapIds.collateral,
		title: "Raptor One's Helmet",
		desc: "At the back of the Helipad Area. it's at the base of the helipad, at the bottom of a staircase.",
		img: '6KuLN8H',
	},
	{
		id: 'RS5Ar4',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [256.92710668692445, 121.5625],
		map: MapIds.armada,
		title: 'Toxic Growth Canister',
		desc: "The western most Capsized area. it's under the water on a platform near the front of the ship.",
		img: 'wQDzgYo',
	},
	{
		id: 'RS5Ar5',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [196.9832618443238, 133.5],
		map: MapIds.armada,
		title: 'Requiem Helicopter Model',
		desc: 'In the Maintenance section. Near one of the doors.',
		img: 'I4f09pE',
	},
	{
		id: 'RS5Ar6',
		faction: Faction.Requiem,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [314.54777218320953, 397.74424238182837],
		map: MapIds.armada,
		title: 'Agent Klaus Model',
		desc: "In the North-eastern most Hangar area. He's hanging off some pipes just above one of the staircases, climb on the nearby toolbox to grab him.",
		img: 'Qn0dwLb',
	},
	{
		id: 'RS6Au1',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [328.7872515067342, 221.11097924801237],
		map: MapIds.forsaken,
		title: 'Symbiotic Relationship',
		desc: 'In the storage zone rooftops area, on top of a wire spool next to the mystery box. It only spawns, after progressing through the main quest (more precisely, after the crystal core drops from the sky, which is part of the wonder weapon).',
		img: 'PYbde8y',
	},
	{
		id: 'RS6Au2',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [193.361937897806, 267.4743705907465],
		map: MapIds.forsakenUnderground,
		title: 'Russian to Russian',
		desc: 'In the office area, on the floor next to the desks and Wunderfizz machine.',
		img: '1kV6UPW',
	},
	{
		id: 'RS6Au3',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [115.67661364380001, 222.11087874406564],
		map: MapIds.forsaken,
		title: 'For Health',
		desc: 'In the bar, on top of the counter.',
		img: 'N5FxkHZ',
	},
	{
		id: 'RS6Au4',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [254.59109465195596, 73.70455695553227],
		map: MapIds.forsaken,
		title: 'The Zakarpatska Oblast',
		desc: 'In the staging area, on top of a crate next to a truck.',
		img: 'qlf1gId',
	},
	{
		id: 'RS6Ar1',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [192.94132033870045, 217],
		map: MapIds.zoo,
		title: "Weaver's Eyepatch",
		desc: 'Behind the desk at the gas station counter.',
		img: '1gYdyUD',
	},
	{
		id: 'RS6Ar2',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [233.49143289914954, 155],
		map: MapIds.golova,
		title: 'Tesla Storm Canister',
		desc: 'On top of the transformer.',
		img: 'GCO1WXK',
	},
	{
		id: 'RS6Ar3',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [157.43164326675438, 121.59375],
		map: MapIds.sanatorium,
		title: 'Director Mug',
		desc: 'On top of a small round table on the ground floor.',
		img: 'rCf8A7L',
	},
	{
		id: 'RS6Ar4',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [112.54451034857252, 166.98547672827868],
		map: MapIds.forsaken,
		title: 'Monitoring Device',
		desc: 'In the TV store, part of the main quest.',
		img: 'UlujB1r',
	},
	{
		id: 'RS6Ar5',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [212.23422995682867, 373.5],
		map: MapIds.forsakenUnderground,
		title: 'Housing Unit',
		desc: 'In the board room, part of the main quest.',
		img: 'f2TL9eI',
	},
	{
		id: 'RS6Ar6',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [386.40504451376614, 260.86230015702665],
		map: MapIds.forsaken,
		title: 'Aetherium Neutralizer',
		desc: 'In the main street area, part of the main quest.',
		img: 'TOHSDW0',
	},
	{
		id: 'RS6Ar7',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [224.84742021412936, 264.4752320531471],
		map: MapIds.forsaken,
		title: 'Fuel Tank',
		desc: 'In the fuel processing area, part of the main quest.',
		img: 'fTtfpLM',
	},
	{
		id: 'RS6Ar8',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [407.51584368211905, 227.17645449055067],
		map: MapIds.forsakenUnderground,
		title: 'The Chrysalax',
		desc: 'Part Of the Main Quest, After all the crystals are in the tub Melee 10 zombies.',
		img: 'HcykNAE',
	},
	{
		id: 'RS6Do1',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Page Six',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do2',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'B-14 Wiretap Transcript',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do3',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Ancient Parchment',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do4',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Collapse Report',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do5',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Ancient Parchment 2',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do6',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Requiem Neutralizer Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do7',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Memorandum For The Board',
		desc: DefaultPOIData.special,
	},
	{
		id: 'RS6Do8',
		faction: Faction.Requiem,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Progress Report',
		desc: DefaultPOIData.special,
	},
	/////////////////////Omega/////////////////////////
	{
		id: 'OS0Au1',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [326.96117219365135, 226],
		map: MapIds.dieMaschineUnderground,
		title: "8th Guard's First Contact",
		desc: 'Sitting on the ground next to the door to the bunker in Tunnel.',
		img: 'J0jJvZT',
	},
	{
		id: 'OS0Au2',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [295.48907164390846, 373],
		map: MapIds.dieMaschineUnderground,
		title: 'The Volunteer',
		desc: "In the room where the PaP part can spawn in med bay. It's the area where the aether tunnel droops you off.",
		img: 'en1j0my',
	},
	{
		id: 'OS0Au3',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [215.25042544443048, 178.37363698684229],
		map: MapIds.dieMaschineUnderground,
		title: 'For Mother Russia',
		desc: "In the room where the PaP part can spawn in the weapon lab.It's the area where the aether tunnel droops you off.",
		img: 'SR4ia1L',
	},
	{
		id: 'OS0Do1',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'An Explanation',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS0Do2',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Before The Devil Arrives',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS0Do3',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'K Cipher',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS0Do4',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'G Cipher',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS0Ar1',
		faction: Faction.Omega,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [253.24830253899938, 354.1305763783566],
		map: MapIds.dieMaschine,
		title: 'Orlov Family Photo',
		desc: 'Collected in the radio station in nacht at the end of the main quest.',
		img: 'm4HN82i',
	},
	{
		id: 'OS1Au1',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [279.46622628125556, 301.5],
		map: MapIds.dieMaschine,
		title: 'Turning Point',
		desc: 'In the bedroom on a table underneath the chalkboard.',
		img: '34pPvvg',
	},
	{
		id: 'OS1Au2',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [408.452500443341, 376],
		map: MapIds.dieMaschine,
		title: 'Next Phase',
		desc: 'In a far corner at the crash site.',
		img: 'H55Wl6J',
	},
	{
		id: 'OS1Au3',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [425.9506384110658, 396.5],
		map: MapIds.firebaseZ,
		title: 'Dimensional Fertility',
		desc: 'In Scorched Defence, on top of the building with the minigun.',
		img: 'fbOjJHc',
	},
	{
		id: 'OS1Au4',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [381.95532009221495, 294.5],
		map: MapIds.firebaseZ,
		title: 'Let It Ride',
		desc: 'On top of some pipes in Engineering. Shoot it down.',
		img: 'vqMaIuF',
	},
	{
		id: 'OS1Au5',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [402.4531388544068, 263],
		map: MapIds.firebaseZ,
		title: 'Aethernauts',
		desc: 'In Planning Offices under the desk in the middle of the room.',
		img: 'yCXoouw',
	},
	{
		id: 'OS1Au6',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [294.46463025359105, 365.5],
		map: MapIds.firebaseZ,
		title: 'The Anatomy Lesson',
		desc: 'In field Hospital, near the exit to the Mission control area.',
		img: 'dfBGGM5',
	},
	{
		id: 'OS1Au7',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [170.97777088136195, 290.5],
		map: MapIds.firebaseZ,
		title: "Kuhlklay's Payback",
		desc: 'On a shelf in weapons lab , directly across from the RAI K-84 blueprint.',
		img: 'ICiiVIE',
	},
	{
		id: 'OS1Au8',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [275.4666518886327, 204],
		map: MapIds.firebaseZ,
		title: 'Gorev',
		desc: 'In colonels office, under the desk with the monitor bank.',
		img: 'gpku3En',
	},
	{
		id: 'OS1Au9',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [388.2945395012534, 305.1142133058241],
		map: MapIds.firebaseZ,
		title: 'To Dust You Will Return',
		desc: 'Inside the OPC(part of the main quest).',
		img: 'mKgojHy',
	},
	{
		id: 'OS1Au10',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [433.4498403972335, 329],
		map: MapIds.firebaseZ,
		title: 'Outpost 25 Has Fallen',
		desc: 'In Mission Control, on a table by a window, near the top most charge collector.',
		img: 'Tp6UZhr',
	},
	{
		id: 'OS1Do1',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Omega Group Formation',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do2',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Operation Undertaker',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do3',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Khe Sanh Site',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do4',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Authorization to Conscript',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do5',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Omega Teleporter Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do6',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Aetherium Production Report',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do7',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Specimen K7-1924',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do8',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'P Cipher',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Do9',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'G Cipher',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS1Ra1',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Good Little Lemmings',
		desc: '',
	},
	{
		id: 'OS1Ra2',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Not Built To Last',
		desc: '',
	},
	{
		id: 'OS1Ra3',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Do Try To Stay Alive',
		desc: '',
	},
	{
		id: 'OS1Ra4',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: "Gorev's Disappointment",
		desc: '',
	},
	{
		id: 'OS1Ar1',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [339.95978896967546, 447],
		map: MapIds.firebaseZ,
		title: "Kuhlklay's Eye",
		desc: 'In scorched defence, in a dead body leaning up against a blown up tank.',
		img: 'q5ZddoH',
	},
	{
		id: 'OS1Ar2',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [374.45611810604714, 265],
		map: MapIds.firebaseZSpawn,
		title: 'Ravenov ID Badge',
		desc: 'Obtained from Ravenov during the main quest.',
		img: 'Q2zH0bd',
	},
	{
		id: 'OS1Ar3',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [288.46526866465683, 368],
		map: MapIds.firebaseZ,
		title: 'Agent Delivery System',
		desc: 'Obtained from the medic building during the main quest.',
		img: 'qHRfsEJ',
	},
	{
		id: 'OS1Ar4',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [170.4778240822841, 354],
		map: MapIds.firebaseZ,
		title: 'Essence Trap',
		desc: 'Obtained from the datacenter during the main quest.',
		img: 'gFj1WrM',
	},
	{
		id: 'OS1Ar5',
		faction: Faction.Omega,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Aethermeter',
		desc: 'Obtained during the main quest.',
		img: 'f1MWrhy',
	},
	{
		id: 'OS2Au1',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [346.95904415676534, 229],
		map: MapIds.firebaseZSpawn,
		title: 'Half Of The Reason',
		desc: 'Under a bunk bed in Scientist Quarters, in the starting area.',
		img: 'WuV5c4u',
	},
	{
		id: 'OS2Au2',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [109.45970063327576, 359.458999774724],
		map: MapIds.golova,
		title: 'Ghost Stories',
		desc: "In the big building in the little cemetary's office room.",
		img: 'oWOCJXd',
	},
	{
		id: 'OS2Au3',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [272.5, 193],
		map: MapIds.sanatorium,
		title: 'Something I Can Use',
		desc: 'In the sanatorium building on the second floor, on a coffee table in the room with the wall-buy.',
		img: 'PPoROBv',
	},
	{
		id: 'OS2Au4',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [250.87823834196888, 294.92122099571975],
		map: MapIds.golova,
		title: 'Off The Hook',
		desc: 'In front of the Wunderfizz, in one of the side rooms.',
		img: 'TRiMC1O',
	},
	{
		id: 'OS2Au5',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [245, 373.5],
		map: MapIds.alpine,
		title: 'Do What You Must',
		desc: 'On a little table on the balcony terrace in the Chalet area.',
		img: 'aHz6RQd',
	},
	{
		id: 'OS2Au6',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [178.97691966660756, 202.5],
		map: MapIds.ruka,
		title: 'In The Wild',
		desc: 'In the middle of the Missile Silos on a shelf. near the ammo crate.',
		img: 'Qr0rnCQ',
	},
	{
		id: 'OS2Au7',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [242, 192.5],
		map: MapIds.sanatorium,
		title: 'Proposed Targets',
		desc: 'In the sanatorium building on the first floor in a side room to the right of the statue.',
		img: 'vhF0WcT',
	},
	{
		id: 'OS2Au8',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [358.81943157106696, 214.25],
		map: MapIds.ruka,
		title: 'Lightning Bugs',
		desc: 'On a table near the edge of the Mobile ICBM Site.',
		img: 'y1Y9jZN',
	},
	{
		id: 'OS2Do1',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'New Frontiers',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS2Do2',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Omega Staffing Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS2Do3',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Omega Records Review',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS2Ra1',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'The Colonel',
		desc: '',
	},
	{
		id: 'OS2Ra2',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Up To Speed',
		desc: '',
	},
	{
		id: 'OS2Ra3',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Fiasco',
		desc: '',
	},
	{
		id: 'OS2Ra4',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Trespassers',
		desc: '',
	},
	{
		id: 'OS2Ar1',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [144.98053732931368, 155.5],
		map: MapIds.ruka,
		title: "Kravchenko's Knife",
		desc: 'In a green lookout tower in the southwest corner of the Missile Silos.',
		img: 's2UT8Be',
	},
	{
		id: 'OS2Ar2',
		faction: Faction.Omega,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [252.46909913105162, 213.5],
		map: MapIds.ruka,
		title: 'Omega Group Patch',
		desc: 'On top of the climbable net in the obstacle course. (might currently be bugged)',
		img: 'nAPQUJs',
	},
	{
		id: 'OS3Au1',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [164.5, 355],
		map: MapIds.duga,
		title: 'Leadership Techniques',
		desc: 'On the third floor of the Admin Offices. In the room with a big oval table.',
		img: 'Jqcx8Hm',
	},
	{
		id: 'OS3Au2',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [211.5, 448.5],
		map: MapIds.duga,
		title: "This Isn't My Work",
		desc: 'In the Research Labs. In the right most building.',
		img: 'et4z5nx',
	},
	{
		id: 'OS3Au3',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [164.23960365312996, 187.75],
		map: MapIds.ruka,
		title: 'Witching Hour',
		desc: 'At the missile silos by the A elevator. On the side of the building, next to the generators.',
		img: 'htLtQqt',
	},
	{
		id: 'OS3Au4',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [287.25, 195.5],
		map: MapIds.sanatorium,
		title: 'His Plan, Our Plan',
		desc: "On the sanatorium Roof. It's on top of the spire with 2 satellite dishes. Requires a Jump pad to get to.",
		img: 'RxOyQRb',
	},
	{
		id: 'OS3Do1',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Boost Module Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do2',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Phase Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do3',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Krasny Soldat Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do4',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Valentina Diary Entry',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do5',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Operatsiya Inversiya Proposal',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do6',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Aetherium Warhead Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Do7',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'R Cipher',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS3Ra1',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Uncle',
		desc: '',
	},
	{
		id: 'OS3Ra2',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Made Of Fire',
		desc: '',
	},
	{
		id: 'OS3Ra3',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Chasing Ghosts',
		desc: '',
	},
	{
		id: 'OS3Ra4',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Something Is Coming',
		desc: '',
	},
	{
		id: 'OS3Ar1',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [281.96596027664475, 373],
		map: MapIds.ruka,
		title: 'Mangler Cannon',
		desc: "Near the base of the Train Trestle closest to the building. It's sitting at the base of a tree.",
		img: 'ZVVaJ0M',
	},
	{
		id: 'OS3Ar2',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [129.25, 274],
		map: MapIds.sanatorium,
		title: "Peck's ID Badge",
		desc: 'In the middle of the large Bathhouse, on a shelf.',
		img: 'dY2WA6X',
	},
	{
		id: 'OS3Ar3',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [164.5, 345.5],
		map: MapIds.duga,
		title: 'Boost Module Monitor',
		desc: 'In the admin offices on the third floor. In the room with all the computers.',
		img: 'qORhNA6',
	},
	{
		id: 'OS3Ar4',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.ruka,
		title: 'ICBM Launch Key',
		desc: 'Obtained during the outbreak EE.',
		img: 'MCMcuOF',
	},
	{
		id: 'OS3Ar5',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.ruka,
		title: 'Modified Essence Trap',
		desc: 'In a hallway on the ground floor of Silo A, obtained during the outbreak EE.',
		img: 'X6CceYX',
	},
	{
		id: 'OS3Ar6',
		faction: Faction.Omega,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [383, 249],
		map: MapIds.alpine,
		title: 'Tempest Head',
		desc: 'In the medevac area. Inside the large building, on top of a pile of crates and sandbags that need to be clambered up.',
		img: 'gT0QBBh',
	},
	{
		id: 'OS4Au1',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [167.97809008689484, 313.5],
		map: MapIds.zoo,
		title: 'Greater Threat',
		desc: 'On a couch on the first floor of the Restaurant',
		img: '7o9yGgU',
	},
	{
		id: 'OS4Au2',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [189.47580244724242, 329],
		map: MapIds.zoo,
		title: 'Day Of Victory',
		desc: 'On top of the rectangular tower of the aquarium. Might need to use a jump pad..',
		img: 'Yj7cMvH',
	},
	{
		id: 'OS4Au3',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.sanatorium,
		title: 'The Survivors',
		desc: 'Obtained during the Outbreak EE#2',
		img: 'h7MQ5lh',
	},
	{
		id: 'OS4Au4',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.sanatorium,
		title: 'The Device',
		desc: 'Obtained during the Outbreak EE#2',
		img: '6c7VIeH',
	},
	{
		id: 'OS4Au5',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.sanatorium,
		title: 'The Trap',
		desc: 'Obtained during the Outbreak EE#2',
		img: 'Ga4nSId',
	},
	{
		id: 'OS4Au6',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [152.24382307164962, 260.125],
		map: MapIds.duga,
		title: 'Peace Is Our Profession',
		desc: 'Underground bunker in the south, under the bed.',
		img: 'tTZCw3w',
	},
	{
		id: 'OS4Au7',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [240.70833873747011, 61.25],
		map: MapIds.sanatorium,
		title: 'A Trap Is Set',
		desc: 'The audio log is in the attic of one of the cottages, on a table with a lampshade.',
		img: 'zJOU7lM',
	},
	{
		id: 'OS4Au8',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [372.4417928146597, 249],
		map: MapIds.alpine,
		title: 'Play Your Part',
		desc: 'The audio log is in the warehouse in the middle of a table.',
		img: 'EaCvraP',
	},
	{
		id: 'OS4Au9',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [156.2484627011629, 160.65870156772797],
		map: MapIds.mauerDerToten,
		title: 'A Decent Proposal',
		desc: 'In the Sewer Access area. In between a Staircase and the rappel point to the West Berlin Streets.',
		img: 'EDzYkkX',
	},
	{
		id: 'OS4Au10',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [189.09552897404927, 55.037560465617595],
		map: MapIds.mauerDerTotenStreets,
		title: 'The Letter',
		desc: 'In the Blasted Suite. Under the bed.',
	},
	{
		id: 'OS4Au11',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [192.98132363950188, 166.56126147457007],
		map: MapIds.mauerDerTotenStreets,
		title: 'The Turn',
		desc: 'In the "Death Strip". Sitting on top of a guard tower, can be shot down from the Destroyed Penthouse.',
		img: '1SgRSRO',
	},
	{
		id: 'OS4Do1',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Ravenov Burn Notice',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do2',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.sanatorium,
		title: 'Aetherium Neutralizer Blueprint.',
		desc: 'Obtained during the Outbreak EE#2',
		img: 'twcX4Yp',
	},
	{
		id: 'OS4Do3',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Necro-Analytics',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do4',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'LT53 Kazimir Blueprint',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do5',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Angelika Vogel Summary',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do6',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Operation Baldr Approval',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do7',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Valentina Diary 15-07-83',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do8',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Statement Of Intent',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Do9',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'F Cipher',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS4Ra1',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: "Omega's Reputation",
		desc: 'Collected through the Omega Radios in Outbreak',
	},
	{
		id: 'OS4Ra2',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'The One-Eyed Cowboy',
		desc: 'Collected through the Omega Radios in Outbreak',
	},
	{
		id: 'OS4Ra3',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Your Problem Now',
		desc: '',
	},
	{
		id: 'OS4Ra4',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Secrets Harbored',
		desc: '',
	},
	{
		id: 'OS4Ra5',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: '5-Star Treatment',
		desc: '',
	},
	{
		id: 'OS4Ra6',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Not For Me',
		desc: '',
	},
	{
		id: 'OS4Ra7',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Leverage',
		desc: '',
	},
	{
		id: 'OS4Ar1',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [106.98458059939702, 268],
		map: MapIds.zoo,
		title: 'Aether Reactor Model.',
		desc: 'Between the Rail holds on top of the support pillar.',
		img: 'Qh5br3O',
	},
	{
		id: 'OS4Ar2',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [187.97787728320623, 345],
		map: MapIds.zoo,
		title: 'Inversion Warhead Model',
		desc: 'Up on the roof between a wooden wall and the rocks.',
		img: '6zMWZHo',
	},
	{
		id: 'OS4Ar3',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [191.30800337218136, 277.62147784531714],
		map: MapIds.sanatorium,
		title: 'Dark Aether Photos',
		desc: 'The artifact is on a side table inside one of the rooms of Upper Suites.',
		img: '1XxPFY0',
	},
	{
		id: 'OS4Ar4',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [243.48189768579425, 201.375],
		map: MapIds.ruka,
		title: 'Omega Flag',
		desc: 'The artifact is inside the watchtower, in the wall besides the window.',
		img: '86s8tdI',
	},
	{
		id: 'OS4Ar5',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [175.67475241333307, 400],
		map: MapIds.zoo,
		title: 'Krasny Soldat Flamethrower',
		desc: "The artifact is in the backroom of the 'Wolf Exhibit', on a shelf.",
		img: 'hQnoV1a',
	},
	{
		id: 'OS4Ar6',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [155.04142581255437, 150.71763984611835],
		map: MapIds.mauerDerToten,
		title: 'Endstation Lure',
		desc: 'Part of the EE, enter the Secret Lab and shoot the machine next to the portal frame. Can only be obtained after collecting and distributing the canisters',
		img: 'S9WRizi',
	},
	{
		id: 'OS4Ar7',
		faction: Faction.Omega,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Battery',
		desc: 'Can be obtained by killing the first Krazny',
		img: 'chnzEpa',
	},
	{
		id: 'OS5Au1',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [342.85771988660576, 424.56079740862197],
		map: MapIds.collateral,
		title: 'Change Of Plans',
		desc: 'On the top of the third building in a row in the Village area. The screen will say you are in the Roadside area when you are on the correct building. The correct building also has a white/blue parachute hanging over one if the corners.',
		img: 'GZRhmoV',
	},
	{
		id: 'OS5Au2',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [187.18029705767913, 120.42651763576742],
		map: MapIds.armada,
		title: 'New Frontiers',
		desc: "In the Hangar area. It's sitting on a blue Shelf.",
		img: 'faMdLpd',
	},
	{
		id: 'OS5Au3',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [329.23846255396415, 263.3665520275134],
		map: MapIds.collateral,
		title: 'Growing Concerns',
		desc: "In the Cliffs area. It's at the edge of the top most set of rocks, up against the lowermost rock in the set. The location overlooks the village area.",
		img: '0QL16lc',
	},
	{
		id: 'OS5Do1',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Berlin Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS5Do2',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Enemy Engagement Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS5Do3',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Operatsiya Izbavitel Orders',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS5Do4',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Kravchenko Letter',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS5Do5',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Test Site Anna Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'OS5Ar1',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [269.98189768579425, 134.2308166040151],
		map: MapIds.collateral,
		title: 'Boost Module Model',
		desc: 'In the Cliffs West area. in between the 2 middle clumps on the minimap.',
		img: '6rcKx3H',
	},
	{
		id: 'OS5Ar2',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [287.7351841729818, 316.2450221946733],
		map: MapIds.collateral,
		title: 'Mimic Tentacle',
		desc: "Inside a pipe in the Village Dunes area, right next to a small oil drill. It's very close to the Parking Lot area.",
		img: 'IIolBRD',
	},
	{
		id: 'OS5Ar3',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [205.37164750514165, 222.17909101815567],
		map: MapIds.collateral,
		title: "Valentina's ID Badge",
		desc: "In the Oil Rig area. it's on the ground level, on a wall to the left when facing the Rappel Rope.",
		img: 'pcSy4Rf',
	},
	{
		id: 'OS5Ar4',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [194.86678494006043, 336.2448402383428],
		map: MapIds.armada,
		title: "Gorev's ID Badge",
		desc: "In the Patrol Boat area. It's on top of the boat itself.",
		img: 'YUxVmzm',
	},
	{
		id: 'OS5Ar5',
		faction: Faction.Omega,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [239.7265290619036, 396.23804286971125],
		map: MapIds.armada,
		title: 'Teleporter Model',
		desc: "In the eastern most Capsized area. It's underwater, along the guardrails of the deck.",
		img: '68j8tJD',
	},
	{
		id: 'OS6Ar1',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [141.11727408456636, 323.75],
		map: MapIds.alpine,
		title: 'Contaminated Crystal',
		desc: 'Yellow/orange crystals in the toilet bowl in the westernmost of the four doors.',
		img: '8WsW1hG',
	},
	{
		id: 'OS6Ar2',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [182.24077282244167, 240.6875],
		map: MapIds.duga,
		title: "Jager's ID Badge",
		desc: 'On the desk as you walk in.',
		img: 'al9W49e',
	},
	{
		id: 'OS6Au1',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [213.46177113635102, 360.5],
		map: MapIds.zoo,
		title: 'Codename Whiskers',
		desc: 'Under the table inside the building.',
		img: 'yams9th',
	},
	{
		id: 'OS6Au2',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [392.92004386615037, 250.5625],
		map: MapIds.duga,
		title: 'Lineage',
		desc: 'In the bunker, on the floor in the computer room, behind the door.',
		img: 'QkBpDd6',
	},
	{
		id: 'OS6Au3',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [115.3396428835883, 167.2192018163428],
		map: MapIds.forsaken,
		title: 'The Agreement',
		desc: 'In the TV store, drops after using the ARC-XD during the main quest step.',
		img: 'Xtk9lSr',
	},
	{
		id: 'OS6Au4',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [319.84422421300326, 160.75],
		map: MapIds.forsakenUnderground,
		title: 'Fully Operational',
		desc: 'In the amplifier area, leans against the railing on the right side before you go down the stairs.',
		img: 'FSUOES7',
	},
	{
		id: 'OS6Au5',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [247.70418142073183, 243.117709648726],
		map: MapIds.forsaken,
		title: 'The Magnificent Two',
		desc: 'In the fuel processing area, leans against a wall left towards the teleporter.',
		img: 'bhxXWIM',
	},
	{
		id: 'OS6Au6',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [400.15504451376614, 68.22878603696722],
		map: MapIds.forsaken,
		title: 'Deja Vu',
		desc: 'Leans against the small checkpoint building.',
		img: 'nQTOc2W',
	},
	{
		id: 'OS6Do1',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.zoo,
		title: 'Operatsiya Izbavitel Approval',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do2',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.duga,
		title: 'Order Of Operations',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do3',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Test Site Construction Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do4',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsakenUnderground,
		title: 'Containment Chamber Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do5',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Location Targets',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do6',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Aetherium Gun Turret Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do7',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsakenUnderground,
		title: 'Forsaken Photograph',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do8',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'ARC-XD Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'OS6Do9',
		faction: Faction.Omega,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Anton Note',
		desc: DefaultPOIData.special,
	},
	/////////////////////Maxis/////////////////////////
	{
		id: 'MS0Au1',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [155.47942010994853, 256],
		map: MapIds.dieMaschine,
		title: 'Cold War Paranoia',
		desc: 'On the couch next to the ee song cassette in first floor of nacht.',
		img: 'meoam69',
	},
	{
		id: 'MS0Au2',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [242.10603552472378, 250.03564086943598],
		map: MapIds.dieMaschine,
		title: 'Game Changer',
		desc: 'Drops from the crate used in the D.I.E Cryo upgrade. The log will be near the destroyed crate and vial.',
		img: 'lEbpEFo',
	},
	{
		id: 'MS0Au3',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: [142.9807501330023, 282.5],
		map: MapIds.dieMaschine,
		title: 'The VHS Tape',
		desc: 'same place as the canister for the nova 5 WW upgrade. Suck it with the WW.',
		img: 'Z2AYL3P',
	},
	{
		id: 'MS0Do1',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'KGB Videotape Note',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS0Do2',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: "Dieter's Note",
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS0Do3',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Message For Weaver',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS0Ra1',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Agent Maxis',
		desc: '',
	},
	{
		id: 'MS0Ra2',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Scorpions From Frogs',
		desc: '',
	},
	{
		id: 'MS0Ra3',
		faction: Faction.Maxis,
		season: Season.Preseason,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: "Why You're Here",
		desc: '',
	},
	{
		id: 'MS1Au1',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [356.45803333924454, 166],
		map: MapIds.firebaseZSpawn,
		title: 'Not Who We Are',
		desc: 'In the scientist quarters, on the inaccessible floor above you the audio log will be sitting, shoot it and it will be on the floor in the Briefing room.',
		img: 'DYy4bGn',
	},
	{
		id: 'MS1Au2',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [133.4817609505231, 216.5],
		map: MapIds.firebaseZSpawn,
		title: 'Workers Of The Worlds',
		desc: 'In equipment storage, on the back wall of the ground floor, on a shelf.',
		img: '7HpX7fQ',
	},
	{
		id: 'MS1Au3',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: [263.0644128266228, 323.67997920450915],
		map: MapIds.firebaseZ,
		title: 'Worse Than Baton Rouge',
		desc: 'Inside a locker in the barracks. Need the key from the free WW quest to unlock.',
		img: 'y6lAhGF',
	},
	{
		id: 'MS1Do1',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Omega Portal Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS1Do2',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Omega Propaganda Poster',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS1Do3',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Aetherium Effects Report',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS1Do4',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Aether Reactor Blueprint',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS1Ra1',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'The New Threat',
		desc: '',
	},
	{
		id: 'MS1Ra2',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Divided Loyalties',
		desc: '',
	},
	{
		id: 'MS1Ra3',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Shared History / Old Friends',
		desc: '',
	},
	{
		id: 'MS1Ra4',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Code In',
		desc: '',
	},
	{
		id: 'MS1Ra5',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Relentless',
		desc: '',
	},
	{
		id: 'MS1Ra6',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Monsters Are Real',
		desc: '',
	},
	{
		id: 'MS1Ra7',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'The Perpetual Night',
		desc: '',
	},
	{
		id: 'MS1Ra8',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'The Light Within',
		desc: '',
	},
	{
		id: 'MS1Ra9',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Blood On My Hands',
		desc: '',
	},
	{
		id: 'MS1Ra10',
		faction: Faction.Maxis,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'A Bad Dream',
		desc: '',
	},
	{
		id: 'MS2Au1',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [379.9555328959035, 311.5],
		map: MapIds.firebaseZ,
		title: 'More Bad News',
		desc: 'At the base of the OPC. Gotta climb over a barrier near the entrance to Mission Control.',
		img: '0S5juLS',
	},
	{
		id: 'MS2Au2',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [147.25338269196664, 295.75],
		map: MapIds.ruka,
		title: 'Call Me Mac',
		desc: 'In the small cabin in the Backwoods.',
		img: 'zYSSX3i',
	},
	{
		id: 'MS2Au3',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [370, 405],
		map: MapIds.alpine,
		title: 'My Reputation Precedes Me',
		desc: "Inside the Red building's office area, in Maintenance.",
		img: 'CKSFkgH',
	},
	{
		id: 'MS2Au4',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [353.8189407023604, 375.46837125478714],
		map: MapIds.golova,
		title: "It's All Relative",
		desc: 'In the little barn next to the 4 silos in Lower Farmstead.',
		img: 'SyDjLUw',
	},
	{
		id: 'MS2Au5',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: [353.5, 146.5],
		map: MapIds.sanatorium,
		title: "We've Both Been To Hell",
		desc: 'In the little reception area of the biggest building in the maintenance area.',
		img: 'ug9LwTt',
	},
	{
		id: 'MS2Do1',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Maxis Status Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS2Do2',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Maxis Diary Entry',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS2Ra1',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'A Tangled Web',
		desc: '',
	},
	{
		id: 'MS2Ra2',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'I Made A Promise',
		desc: '',
	},
	{
		id: 'MS2Ra3',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lab Rat',
		desc: '',
	},
	{
		id: 'MS2Ra4',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Three Out Of Four',
		desc: '',
	},
	{
		id: 'MS2Ra5',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Both Ways',
		desc: '',
	},
	{
		id: 'MS2Ra6',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: "I've Changed",
		desc: '',
	},
	{
		id: 'MS2Ra7',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Why I Fight',
		desc: '',
	},
	{
		id: 'MS2Ra8',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'True Courage',
		desc: '',
	},
	{
		id: 'MS2Ar1',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [213.3998272884283, 288.41741383194415],
		map: MapIds.golova,
		title: 'Endstation Video Tape',
		desc: 'Found in a room in Lower East Town on an end table by a bed.',
		img: 's5WyEKN',
	},
	{
		id: 'MS2Ar2',
		faction: Faction.Maxis,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [165.9271732872769, 420.9950214012165],
		map: MapIds.golova,
		title: 'Maxis BND Badge',
		desc: 'Behind some sandbags, very close to the edge of the map, in the Industrial Park location.',
		img: '92zeyEQ',
	},
	{
		id: 'MS3Au1',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [338, 218],
		map: MapIds.duga,
		title: "It's Always Dark",
		desc: 'In the 3rd bunker down from the top in the Bunkers section of the map, on a box.',
		img: 'SURTqur',
	},
	{
		id: 'MS3Au2',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [346.5, 56],
		map: MapIds.duga,
		title: 'Outbursts',
		desc: 'In the farm area. In the big red house, on the bed on the upper floor.',
		img: 'QXmHPEY',
	},
	{
		id: 'MS3Au3',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [91.47006332757627, 339.4472854246452],
		map: MapIds.golova,
		title: 'A New Friend',
		desc: "On a desk in one of the Church's back rooms.",
		img: 'OqzDIo6',
	},
	{
		id: 'MS3Au4',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [171.92371905584338, 341.4484568596531],
		map: MapIds.golova,
		title: 'Isolated',
		desc: 'On a desk in the Police station(the armour station building)',
		img: 'fzjssJr',
	},
	{
		id: 'MS3Au5',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: [261.5, 193],
		map: MapIds.duga,
		title: 'Someone We Can Trust',
		desc: 'In the warehouses section. On the second floor of the top most warehouse, on a desk.',
		img: 'sinLGcX',
	},
	{
		id: 'MS3Do1',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Arrival',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS3Do2',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Maxis Progress Report',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS3Do3',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Quarantine Diary 1',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS3Do4',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Quarantine Diary 2',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS3Ar1',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [296.5, 96],
		map: MapIds.duga,
		title: "Maxis's Katana",
		desc: 'In the farm area. Stuck in a haybale in a shed near the back of the area, next to the stone wall.',
		img: '2GAqORT',
	},
	{
		id: 'MS3Ar2',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [220, 253.5],
		map: MapIds.duga,
		title: "Maxis's Scarf",
		desc: 'In the listening post. In a room just to the left of the Wunderfizz, on a coathook.',
		img: 'FBGUM1T',
	},
	{
		id: 'MS3Ar3',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [141.77868590098416, 139.47542205060716],
		map: MapIds.sanatorium,
		title: "Maxis's Goggles",
		desc: 'In the market square on one of the round tables.',
		img: '8Awvkl1',
	},
	{
		id: 'MS3Ar4',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Beacon Listening Device',
		desc: 'Obtained whilst completing an Aether transmission during the outbreak EE.',
		img: 'XabDmHW',
	},
	{
		id: 'MS3Ar5',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Microfilm Slides',
		desc: 'Obtained by breaking a stone monkey during the outbreak EE.',
		img: 'bO6yQZI',
	},
	{
		id: 'MS3Ar6',
		faction: Faction.Maxis,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [211.40097869890616, 322.9376210858302],
		map: MapIds.golova,
		title: "Maxis's Watch",
		desc: "In the Lower West town area. It's on the roof of the building with the crafting bench, requires a jump pad to get to. It's tucked in between the roof itself and one of the 4 spire things with a flag on it.",
		img: 'OWQQFAy',
	},
	{
		id: 'MS4Au1',
		faction: Faction.Maxis,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [119.48325057634332, 359.5],
		map: MapIds.zoo,
		title: 'Take Her',
		desc: 'In the basement of the zoo facilities in a shelf of one of the work stations',
		img: 'SQQbeyc',
	},
	{
		id: 'MS4Au2',
		faction: Faction.Maxis,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [218.47271679375774, 319.5],
		map: MapIds.zoo,
		title: 'The Confrontation',
		desc: 'In one of the animal cages on some hay',
		img: 'YJSLXmb',
	},
	{
		id: 'MS4Au3',
		faction: Faction.Maxis,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: [359.30741377017205, 350.5],
		map: MapIds.zoo,
		title: 'The Ally',
		desc: 'In the little nook where the stone monkey is on a shelf.',
		img: 'Jo00PhB',
	},
	{
		id: 'MS4Do1',
		faction: Faction.Maxis,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Quarantine Diary 9',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS5Au1',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [368.98544820366493, 361.7437485003599],
		map: MapIds.collateral,
		title: 'Test',
		desc: 'Inside the Building marked Carpets on a table.',
		img: 'AYVdW15',
	},
	{
		id: 'MS5Au2',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [149.6756765207241, 183.9313004878829],
		map: MapIds.collateral,
		title: 'Interrogation',
		desc: 'Inside the Pump Room on a Shelf.',
		img: 'aStmo8p',
	},
	{
		id: 'MS5Au3',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [267.2369443775361, 254.55674238182837],
		map: MapIds.collateral,
		title: 'Fifteen Days',
		desc: "In the Warehouse. it's in a back room, up on a pipe. you need to shoot it down.",
		img: '0sJpUjw',
	},
	{
		id: 'MS5Au4',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [213.54343767949456, 228.8739342557786],
		map: MapIds.collateral,
		title: 'Sixty-Two Days',
		desc: 'In the Oil Rig area. This one is a bit difficult. Climb up to where PaP is. Then look up towards the yellow platform, and you can just barely see the edge of the tape recorder hanging over the edge. Shoot it down, and it will fall down to you.',
		img: 'lmrBTMe',
	},
	{
		id: 'MS5Au5',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: [190.6188531804117, 255.1136667199872],
		map: MapIds.armada,
		title: 'Thirty Seconds',
		desc: 'In the same room as Pap on a table.',
		img: 'vTNZwPn',
	},
	{
		id: 'MS5Do1',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Week 1 Summary',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS5Do2',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Subject Rules',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS5Do3',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Weaver Letter',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS5Do4',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Grey Letter',
		desc: DefaultPOIData.chests,
	},
	{
		id: 'MS5Ra1',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'New Frequency',
		desc: '',
	},
	{
		id: 'MS5Ra2',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Understand Me',
		desc: '',
	},
	{
		id: 'MS5Ra3',
		faction: Faction.Maxis,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Power',
		desc: '',
	},
	{
		id: 'MS6Au1',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [232.85298123066093, 312],
		map: MapIds.ruka,
		title: 'I Am Alive',
		desc: 'On the ledge of the top of the watchtower.',
		img: 'w7NTeIJ',
	},
	{
		id: 'MS6Au2',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [407.05853356142404, 229.72348041293253],
		map: MapIds.forsakenUnderground,
		title: 'My Choice',
		desc: 'In the particle accelerator area, drops out of the aetherium tank after assembling the wonder weapon in there.',
		img: '44r3bWk',
	},
	{
		id: 'MS6Au3',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [418.38774090659103, 179.5548004540857],
		map: MapIds.forsaken,
		title: 'My Orders',
		desc: 'In the Beach Pizza restaurant, on top of the lamp bracket. You need to shoot it down.',
		img: 'APub0rE',
	},
	{
		id: 'MS6Au4',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: [50.866396900649534, 116.21334387201858],
		map: MapIds.forsaken,
		title: 'My Control',
		desc: 'In the video store, on a shelf behind the Mule Kick machine.',
		img: '2fCWOQB',
	},
	{
		id: 'MS6Do1',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.ruka,
		title: 'Ability Assessment',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS6Do2',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Samantha Letter',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS6Do3',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Maxis Approval Memo',
		desc: DefaultPOIData.special,
	},
	{
		id: 'MS6Ra1',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'The Boy',
		desc: '',
	},
	{
		id: 'MS6Ra2',
		faction: Faction.Maxis,
		season: Season.Season6,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'The Promise',
		desc: '',
	},
	/////////////////////Dark Aether/////////////////////////
	{
		id: 'DS0Au1',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Johannes',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Au2',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Mariska',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Au3',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: David',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Au4',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Approach',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Au5',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Alice',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Au6',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Barbara',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do1',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: '$!%()$3@!',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do2',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'I @N#B%D! O% T@#&',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do3',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 13,773',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do4',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 95',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do5',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 175',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do6',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 213',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do7',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 1,646',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do8',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 937',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do9',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'DAY 1,108',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do10',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'DAY 6,121',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do11',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'DAY 2,873',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Do12',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'DAY 3,289',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS0Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [255.6040273179711, 209.5934508684848],
		map: MapIds.dieMaschine,
		title: 'Mutated Fungus',
		desc: 'Obtained during the main quest, on a tree with purple table mushrooms.',
		img: 'jiJAPUQ',
	},
	{
		id: 'DS0Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [200.7678322911951, 178.88682571261361],
		map: MapIds.dieMaschineUnderground,
		title: 'Plaguehound Gas',
		desc: 'Obtained during the main quest, next to Deadshot Daiquiri.',
		img: 'EYww78N',
	},
	{
		id: 'DS0Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [114.01156059366146, 348.68855512651794],
		map: MapIds.dieMaschineUnderground,
		title: 'Dark Aether Crystal',
		desc: 'Obtained during the main quest, collected from the red Aether crystals.',
		img: 'gSh6MRa',
	},
	{
		id: 'DS0Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Preseason,
		typeDesc: IntelType.Artifact,
		loc: [327.44734406193004, 318.7461250716169],
		map: MapIds.dieMaschineUnderground,
		title: 'Dark Aether Wrench',
		desc: 'Obtained during the main quest, gotten from the ghostly figures in the med bay.',
		img: 'ow2AEku',
	},
	{
		id: 'DS1Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Lost Souls: Fedorov',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Au2',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Lost Souls: Koslov',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Au3',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Lost Souls: Tobias',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Au4',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Lost Souls: Olga',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Au5',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Fritz',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Au6',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Lost Souls: Pyotr',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do1',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'My Friend!',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do2',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 291',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do3',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 3,789',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do4',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 4,010',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do5',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,157',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do6',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,289',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do7',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 2,944',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do8',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,412',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do9',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,416',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do10',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,418',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do11',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Hello?',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do12',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 13,784',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Do13',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'Day 4,100',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra1',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Without Orders',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra2',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'ALIVE',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra3',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'GATHERING',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra4',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'BEHEMOTH',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra5',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'DOMINION',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ra6',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.dieMaschine,
		title: 'HORDE',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS1Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season1,
		typeDesc: IntelType.Artifact,
		loc: [354.96085298811846, 285.5],
		map: MapIds.firebaseZ,
		title: 'Omega Canister',
		desc: 'Part of the Main quest. One of many possible canisters that are filled with white and black smoke, the correct one is pure white.',
		img: 'tLJkx4S',
	},
	{
		id: 'DS2Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Lost Souls: Fedorov #2',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do1',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,421',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do2',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Day 6,457',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do3',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Much More To Tell You',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do4',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Hello Again',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do5',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Divided World',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do6',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Trust Me',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Do7',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'More To Follow',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Ra1',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.firebaseZ,
		title: 'Crooked House',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Ra2',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Testing... Testing...',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Ra3',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'New Enemies',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS2Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [155, 212.5],
		map: MapIds.alpine,
		title: 'Empty Jugger-Nog',
		desc: 'On the roof of the Large Sauna building',
		img: 'GmKRLaf',
	},
	{
		id: 'DS2Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [104.96229130685089, 326.9399639558459],
		map: MapIds.golova,
		title: 'Cymbal Monkey Toy',
		desc: 'On the lip of the left most tower on the roof of the church.',
		img: '9dkegZY',
	},
	{
		id: 'DS2Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [260.1704238411489, 399.954296532133],
		map: MapIds.ruka,
		title: "Ivan's Head",
		desc: 'On a telephone pillar next to the train trestle bridge (use parachute).',
		img: 'QwYbojZ',
	},
	{
		id: 'DS2Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Season2,
		typeDesc: IntelType.Artifact,
		loc: [239.3576897778437, 74.5],
		map: MapIds.alpine,
		title: 'Mystery Box Bunny',
		desc: "In a bed in Base Suites section. It's the third building from the bottom.",
		img: 'bU3zpG7',
	},
	{
		id: 'DS3Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Fedorov 1',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Au2',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Fedorov 2',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Au3',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Fedorov 3',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Au4',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Great and Terrible',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do1',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 1',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do2',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 3',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do3',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 7',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do4',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 9',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do5',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 11',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Do6',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 12',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra1',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Who I Am',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra2',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Great Patriotic',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra3',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Tethered',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra4',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Changes',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra5',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Junkyard',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra6',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Invasion',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ra7',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Bring Me Home',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS3Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [162.5, 462],
		map: MapIds.sanatorium,
		title: 'Empty Speed Cola',
		desc: 'On the counter on the kitchen of the higher of the two right most houses in the vacation homes section.',
		img: 'uFhawNw',
	},
	{
		id: 'DS3Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [316, 286.5],
		map: MapIds.duga,
		title: 'Broken Essence Capsule',
		desc: 'In the Secondary Array. This location is kinda weird. Starting from the top of the left most array, go as far left as you can, then go down 3 layers. It will be sitting on a grey metal bar connecting the walkway to the side of the array. This is away from the Primary Array, not towards it.',
		img: '4FvTHz3',
	},
	{
		id: 'DS3Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [284.5, 238.5],
		map: MapIds.sanatorium,
		title: 'Empty Quick Revive',
		desc: 'In the Beach Resort. Under the stairs of the White shack building.',
		img: 'DUeehQn',
	},
	{
		id: 'DS3Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [279.4652284636858, 147.6332283257859],
		map: MapIds.ruka,
		title: 'Broken Essence Canister',
		desc: 'Right next to the wall buy inside the barn.',
		img: 't5TwvLH',
	},
	{
		id: 'DS3Ar5',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.ruka,
		title: 'Aetherium Crystal Chunks',
		desc: 'Found by breaking crystals in Silo B during the outbreak EE',
		img: 'xQFq6b1',
	},
	{
		id: 'DS3Ar6',
		faction: Faction.Dark_Aether,
		season: Season.Season3,
		typeDesc: IntelType.Artifact,
		loc: [226, 142.5],
		map: MapIds.alpine,
		title: 'Dragon Head Trophy',
		desc: 'In the lodge. In Room 201, above the bed.',
		img: 'QExvSGe',
	},
	{
		id: 'DS4Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'We Are Waiting',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Au2',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Lost Souls: Valentina 1',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Au3',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Lost Souls: Valentina 2',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Au4',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Lost Souls: Valentina 3',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Do1',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 4,513',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Do2',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Day 4,729',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Do3',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Day 4,527',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Do4',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Day 4,771',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Do5',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Day 5,169',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Ra1',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Secret Contact',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Ra2',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Bring Me Home 2',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Ra3',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'The Forsaken',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Ra4',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Consumed',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS4Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [358.4578205355559, 261],
		map: MapIds.zoo,
		title: 'Empty Stamin-Up',
		desc: 'Inside the diner under the white marble counter.',
		img: 'DwJhhdl',
	},
	{
		id: 'DS4Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [206.47021635041676, 299.5],
		map: MapIds.zoo,
		title: 'Fury Crystal',
		desc: 'On the roof behind a pillar.',
		img: '0CRrWbl',
	},
	{
		id: 'DS4Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [186.3576897778437, 331.625],
		map: MapIds.golova,
		title: 'Empty Elemental Pop',
		desc: 'Resting on a food shelf inside the kitchen area.',
		img: 'kiK2Rr8',
	},
	{
		id: 'DS4Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: [236.8503849289433, 327.875],
		map: MapIds.duga,
		title: 'Elemental Pop Poster',
		desc: "The poster is inside the 'Maintenance Facilities' building, fixed on the wall.",
		img: 'RKM8CE7',
	},
	{
		id: 'DS4Ar5',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Electrical Fuse',
		desc: 'Obtained by killing the first tempest, used to restore power',
		img: 'LcCTZ0F',
	},
	{
		id: 'DS4Ar6',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Tempest Essence',
		desc: 'Part of the EE, can be obtained by placing a container on the essence harvester and filling it up by killing the spawning Tempest.',
		img: 'nbh7Pym',
	},
	{
		id: 'DS4Ar7',
		faction: Faction.Dark_Aether,
		season: Season.Season4,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.mauerDerToten,
		title: 'Corrupted Uranium',
		desc: 'Part of the EE, can be obtained by spawning an HVT Megaton and killing the 2 halves',
		img: 'UpeO5Dr',
	},
	{
		id: 'DS5Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Piotr 1',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS5Au2',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Piotr 2',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS5Ra1',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'The Machine',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS5Ra2',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Broken Trust',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS5Ra3',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Radio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Make This Right',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS5Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [238.67594055140722, 68.30086779172998],
		map: MapIds.collateral,
		title: 'Empty Deadshot Daiquiri',
		desc: 'Inside the building marked Hazardous.',
		img: 'sqsllT0',
	},
	{
		id: 'DS5Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [364.92220011672936, 279.24477525393905],
		map: MapIds.collateral,
		title: 'Demented Echo Head',
		desc: "In the Cliffs area. Tucked in the Base of the top most clump on the minimap. It's right where the line connects to the clump on the minimap.",
		img: 'hz1FHKd',
	},
	{
		id: 'DS5Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [244.6159268403402, 188.73986243301607],
		map: MapIds.armada,
		title: 'Empty Mule Kick',
		desc: "In the Machine Shop area. It's inside an overturned trash can.",
		img: 'ctnz9Ae',
	},
	{
		id: 'DS5Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Season5,
		typeDesc: IntelType.Artifact,
		loc: [309.8628684849271, 262.7430596656802],
		map: MapIds.armada,
		title: 'Empty Tombstone Soda',
		desc: "In the Gun boat Control Room. it's in-between one of the sonar panels and the wall.",
		img: 'WoGXHqz',
	},
	{
		id: 'DS6Au1',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Piotr 3',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS6Au2',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Audio,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.allOutbreakMaps,
		title: 'Lost Souls: Piotr 4',
		desc: DefaultPOIData.challenge,
	},
	{
		id: 'DS6Ar1',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [393.3707361360731, 342],
		map: MapIds.ruka,
		title: 'Archaic Goblet',
		desc: 'On top of a cut tree stump.',
		img: 'sYh4g2M',
	},
	{
		id: 'DS6Ar2',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [170.61009616275408, 319.75],
		map: MapIds.golova,
		title: 'Empty PhD Slider',
		desc: 'On top of the desk in a second-floor room to the right of pack-a-punch.',
		img: 'D8Pj7M5',
	},
	{
		id: 'DS6Ar3',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [327.842464008449, 304.25],
		map: MapIds.alpine,
		title: "Zykov's Mask",
		desc: 'On the shelf.',
		img: 'YnDjT7U',
	},
	{
		id: 'DS6Ar4',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [238.2379564951548, 255.125],
		map: MapIds.sanatorium,
		title: 'Empty Death Perception',
		desc: 'In the sink on the ground floor.',
		img: 'y6hCATZ',
	},
	{
		id: 'DS6Ar5',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: [421.0527264879534, 127.71176452428412],
		map: MapIds.forsaken,
		title: 'Polymorphic Crystal Core',
		desc: "In the Jim's Donuts building, the artifact is part of the wonder weapon quest.",
		img: 'rVWYM4B',
	},
	{
		id: 'DS6Ar6',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Energetic Geode',
		desc: "Get an Abomination to Destroy a Dark aether Crystal(the Purple ones you can melee) with it's Laser.",
		img: '9pfNhpv',
	},
	{
		id: 'DS6Ar7',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Tempered Crystal Heart',
		desc: 'Kill a Tormentor with a Flame Thrower.',
		img: 'qVkZGEG',
	},
	{
		id: 'DS6Ar8',
		faction: Faction.Dark_Aether,
		season: Season.Season6,
		typeDesc: IntelType.Artifact,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.forsaken,
		title: 'Catalyzed Crystal Shard',
		desc: 'Part of the EE, can be obtained by killing an Abomination that has eaten a Crystal Shard',
		img: '9VCpNkr',
	},
	//onslaught
	{
		id: 'ROnDo1',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.miami,
		title: 'Late Checkout',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo2',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.miami,
		title: 'Tourist Trap',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo3',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.miami,
		title: 'Eighty-Sixed',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo4',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.satellite,
		title: 'Angola Breach Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo5',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.satellite,
		title: 'Morasko Satellite Capture 08.83',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo6',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.satellite,
		title: 'Morasko Satellite Capture 09.83',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo7',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.moscow,
		title: 'Memorandum from Moscow',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo8',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.moscow,
		title: 'Redpoll Interview Excerpt',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo9',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.moscow,
		title: 'A Formal Request',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo10',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.nuketown,
		title: 'To Whom It May Concern',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo11',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.nuketown,
		title: "Punks Ain't Pacifists",
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo12',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.nuketown,
		title: 'Cellar Dwellers',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo13',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.thePines,
		title: 'Game Over',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo14',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.thePines,
		title: 'Breakup',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo15',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.thePines,
		title: 'Respect',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo16',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.express,
		title: 'State Your Emergency',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo17',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.express,
		title: 'Lightning Rod',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo18',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.express,
		title: 'Los Angeles Outbreak AAR',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo19',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.rush,
		title: 'Comet-Cannibals',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo20',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.rush,
		title: 'Game Plan',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo21',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.rush,
		title: 'Done And Dusted',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo22',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.echelon,
		title: "The Devil's Mountain",
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo23',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.echelon,
		title: 'A Lovely Addition',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo24',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.echelon,
		title: 'Echelon Breach Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo25',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.driveIn,
		title: 'Dear Dad',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo26',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.driveIn,
		title: 'The Beasts from Below',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'ROnDo27',
		faction: Faction.Requiem,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.driveIn,
		title: 'Movie Night Flyer',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo28',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.cartel,
		title: 'Querida Madre: Soldiers of Death',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo29',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.cartel,
		title: "Querida Madre: Raul's Wrath",
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo30',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.cartel,
		title: "Querida Madre: Manuel's Fate",
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo31',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.crossroads,
		title: 'The Last Post: Convoy',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo32',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.crossroads,
		title: 'The Last Post: Cursed',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo33',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.crossroads,
		title: 'The Last Post: Consequences',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo34',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.raid,
		title: 'Housewarming: Canyon Fire',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo35',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.raid,
		title: 'Housewarming: Stash Room',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo36',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.raid,
		title: 'Housewarming: Friends of the Owner',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo37',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.apocalypse,
		title: 'Laos Breach Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo38',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.apocalypse,
		title: 'A Luxury I Cannot Afford',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo39',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.apocalypse,
		title: 'Laos Operation Map',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo40',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.yamantau,
		title: 'Exclusion Zone',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo41',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.yamantau,
		title: 'Field Promotion',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo42',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.yamantau,
		title: 'Yamantau Breach Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo43',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.standoff,
		title: 'Eyes Only: Valentina',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo44',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.standoff,
		title: '3 Surprises',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo45',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.standoff,
		title: 'Krgyzstan Breach Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo46',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.collateralOn,
		title: 'Algeria Aid Request',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo47',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.collateralOn,
		title: 'Krupin Field Report',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'OOnDo48',
		faction: Faction.Omega,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.collateralOn,
		title: 'After-Action: Algeria',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo49',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.checkmate,
		title: 'Hausmeisterin: The Secret',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo50',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.checkmate,
		title: 'Hausmeisterin: The Lie',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo51',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.checkmate,
		title: 'Hausmeisterin: The Visitor',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo52',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.garrison,
		title: 'KGB Contact Note',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo53',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.garrison,
		title: 'Soviet ar Hero',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo54',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.garrison,
		title: 'Red Army Encounter',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo55',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.deprogram,
		title: 'I Know',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo56',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.deprogram,
		title: 'I Dream',
		desc: DefaultPOIData.onslaught,
	},
	{
		id: 'MOnDo57',
		faction: Faction.Maxis,
		season: Season.Onslaught,
		typeDesc: IntelType.Docs,
		loc: DefaultPOIData.nullLoc,
		map: MapIds.deprogram,
		title: 'I Live',
		desc: DefaultPOIData.onslaught,
	},
];

function getIntelStore(intelStore: IIntelItem[]): IntelItem[] {
	var intelListComputed: IntelItem[] = [];
	intelStore.forEach(intel => {
		intelListComputed.push(
			new IntelItem(
				intel.id,
				intel.faction,
				intel.season,
				intel.typeDesc,
				intel.loc,
				intel.map,
				intel.title,
				intel.desc,
				intel.img
			)
		);
	});
	return intelListComputed;
}

export const IntelStore: IntelItem[] = getIntelStore(staticIntelStore);

//Needed to parse location back from a string.
/* export const IntelCache = IntelStore.slice();
let intelFiltered = IntelCache.slice(); */

/* intelCache.map((item) => {
	item.loc = JSON.parse(item.loc)
	return item;
}) */
